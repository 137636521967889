import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 334.000000 357.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,357.000000) scale(0.100000,-0.100000)" >
<path d="M1156 2438 c-3 -13 -10 -63 -16 -113 -12 -105 -85 -668 -100 -770
-34 -240 -42 -300 -47 -393 l-6 -102 174 2 174 3 15 125 15 125 64 3 c45 2 67
-1 72 -10 5 -7 9 -42 10 -78 1 -53 2 -60 9 -36 5 15 15 36 23 45 7 9 50 77 94
151 171 287 164 269 142 369 l-11 50 -121 3 -122 3 -3 50 c-4 65 14 168 39
217 24 46 95 116 127 124 26 7 26 8 7 139 -8 55 -15 103 -15 108 0 4 -117 7
-260 7 l-260 0 -4 -22z m288 -360 c14 -131 46 -485 46 -500 0 -15 -10 -18 -65
-18 -55 0 -65 3 -65 18 0 29 39 393 50 462 5 36 10 71 10 78 0 6 4 12 9 12 5
0 12 -24 15 -52z"/>
<path d="M2049 1700 c-1 -16 -1 -39 -1 -50 0 -50 0 -50 57 -50 37 0 57 -4 61
-13 5 -14 -72 -273 -112 -374 -14 -36 -24 -82 -24 -112 l0 -51 -519 0 c-336 0
-522 -4 -526 -10 -4 -7 226 -10 682 -10 l688 0 -3 70 -4 70 -58 0 c-32 0 -61
4 -65 10 -3 5 22 91 56 192 48 145 62 200 66 266 l6 82 -54 1 c-30 0 -98 3
-151 5 l-98 4 -1 -30z"/>
<path d="M997 993 c-4 -3 -7 -71 -7 -150 l0 -143 68 0 c97 0 101 5 106 123 6
117 -4 152 -46 166 -34 12 -111 15 -121 4z m103 -138 c0 -91 -5 -115 -25 -115
-16 0 -26 195 -11 210 23 23 36 -13 36 -95z"/>
<path d="M1920 850 l0 -150 29 0 c26 0 30 4 35 38 4 28 2 184 -3 255 -1 4 -14
7 -31 7 l-30 0 0 -150z"/>
<path d="M1379 948 c-3 -74 4 -215 12 -229 7 -14 27 -17 129 -16 l120 0 0 101
c0 56 -4 106 -8 113 -12 19 -54 31 -91 25 -25 -3 -36 -12 -46 -35 -17 -41 -9
-59 24 -55 20 2 27 9 29 31 5 38 32 36 32 -3 0 -24 -7 -33 -39 -50 -46 -23
-51 -30 -58 -73 -6 -38 -8 -41 -22 -32 -16 9 -14 182 2 189 10 5 10 7 0 12 -7
3 -13 14 -13 25 0 15 -7 19 -35 19 -29 0 -35 -4 -36 -22z m201 -178 c0 -29 -4
-40 -15 -40 -10 0 -15 10 -15 33 0 19 3 37 7 40 15 16 23 5 23 -33z"/>
<path d="M1233 935 c-54 -23 -56 -84 -2 -85 10 0 19 11 23 31 7 37 30 34 34
-4 3 -22 -3 -29 -41 -46 -47 -22 -57 -35 -57 -81 0 -38 12 -44 91 -47 79 -3
82 1 73 112 -8 105 -10 112 -45 124 -39 13 -37 13 -76 -4z m55 -166 c-2 -22
-8 -34 -18 -34 -19 0 -23 25 -10 60 14 37 33 20 28 -26z"/>
<path d="M1727 926 c-3 -7 -3 -70 0 -140 l6 -127 26 3 c18 2 27 10 29 26 3 20
8 22 33 17 16 -4 38 -4 49 0 19 6 20 13 18 118 l-3 112 -77 2 c-53 1 -78 -2
-81 -11z m103 -106 c0 -83 -1 -90 -20 -90 -18 0 -20 7 -20 83 0 85 3 97 27 97
10 0 13 -22 13 -90z"/>
<path d="M2020 833 c0 -60 4 -113 8 -119 4 -7 37 -12 80 -12 l72 -1 0 120 0
119 -30 0 -29 0 -3 -102 c-3 -89 -5 -103 -20 -106 -16 -3 -18 7 -18 102 l0
106 -30 0 -30 0 0 -107z"/>
<path d="M2216 923 c-3 -8 -6 -50 -6 -93 0 -88 10 -104 62 -98 27 2 33 0 33
-17 0 -18 -6 -20 -47 -17 -27 2 -48 0 -48 -5 0 -21 27 -33 75 -33 79 0 85 11
85 157 l0 120 -74 0 c-57 1 -76 -3 -80 -14z m94 -83 c0 -66 -10 -89 -30 -66
-13 16 -9 113 5 136 13 21 25 -12 25 -70z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
